<template lang="pug">
  .divider
</template>

<script>
export default {};
</script>

<style lang="scss">
.divider {
  margin: 24px 0 16px;
  width: 100%;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
}
</style>
