
import Vue from "vue";
import Component from "vue-class-component";

import Divider from "@/components/ewa/common/Divider.vue";

@Component({
  components: {
    Divider,
  },
})
export default class EwaSuccessPaymentModal extends Vue {
  close() {
    (this.$root as any).closeDialog();
  }
}
